import { Fragment, ReactNode } from 'react'
import { ActionMenuIcon, IconButton, Menu, MenuButton, MenuList } from 'src/components/designsystem'

export interface ResourceListItemActionMenuProps<Item> {
  actionMenuConfig: {
    key: string
    render?: (item: any) => ReactNode
  }[]
  item: Item
}

export const sharedActionMenuButtonProps = {
  size: 'xs',
  variant: 'menuIcon',
  'aria-label': 'More Actions',
  fontSize: 'lg',
  icon: <ActionMenuIcon />,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minW: 'auto',
  w: 6,
  isRound: true,
  'data-testid': 'kebab-button',
}

export default function ResourceListItemActionMenu({
  actionMenuConfig,
  item,
}: ResourceListItemActionMenuProps<any>) {
  return (
    <Menu autoSelect={false}>
      <MenuButton
        as={IconButton}
        {...sharedActionMenuButtonProps}
        onClick={(e) => {
          // Do not expand element underneath if action menu is clicked
          e.stopPropagation()
        }}
      />

      <MenuList>
        {actionMenuConfig?.map((config) => {
          return <Fragment key={config.key}>{config.render(item)}</Fragment>
        })}
      </MenuList>
    </Menu>
  )
}
