import { MobileFullscreenModal } from 'src/components/resource/MobileFullscreenModal'
import { ContractDetailWithData } from './ContractDetail'
import { useConfig } from 'src/data/config'
import { ContractDetailWithDataV3 } from 'src/components/contracts/contracts-v3/ContractDetail'
import { ReactNode } from 'react'
import { FLAG_KEYS, useGlobalFlag } from 'src/utils/flagr'
import ResourceListItemActionMenu from 'src/components/resource/ResourceListItemActionMenu'

export default function ContractDetailModal({
  contract,
  onClose,
  actionMenuConfig,
}: {
  contract: Contract | ContractV3
  onClose: () => void
  actionMenuConfig?: { key: string; render: (item: Contract | ContractV3) => ReactNode }[]
}) {
  const { contracts } = useConfig()
  const contractsVersion = contracts.contractsVersion()
  const isPdfEnabled = useGlobalFlag(FLAG_KEYS.WEBAPP_CONTRACTS_PDF)

  return (
    <MobileFullscreenModal
      primaryTitle={`Contract ${contract.display_id}`}
      secondaryTitle={
        isPdfEnabled && (
          <ResourceListItemActionMenu actionMenuConfig={actionMenuConfig} item={contract} />
        )
      }
      onClose={onClose}
    >
      {contractsVersion === '3.0.0' && <ContractDetailWithDataV3 id={contract.id} />}
      {contractsVersion !== '3.0.0' && <ContractDetailWithData id={contract.id} />}
    </MobileFullscreenModal>
  )
}
