import { Flex, Heading, Spacer } from 'src/components/designsystem'
import ResourceListItemActionMenu, {
  ResourceListItemActionMenuProps,
} from 'src/components/resource/ResourceListItemActionMenu'

interface ActionMenuHeaderProps extends ResourceListItemActionMenuProps<any> {
  title?: string | React.ReactElement
  rightElement?: React.ReactElement
}

export default function ActionMenuHeader({
  actionMenuConfig,
  title,
  item,
  rightElement,
}: ActionMenuHeaderProps) {
  return (
    <>
      <Flex w="100%" alignItems="center" pt={0} mb={5}>
        {title && (
          <Heading type="h5" data-testid="card-header">
            {title}
          </Heading>
        )}
        <Spacer />

        {actionMenuConfig?.length > 0 && (
          <ResourceListItemActionMenu actionMenuConfig={actionMenuConfig} item={item} />
        )}
        {rightElement}
      </Flex>
    </>
  )
}
