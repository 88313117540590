import { MouseEventHandler, useCallback } from 'react'
import { useMutation } from '@tanstack/react-query'
import Link from 'next/link'
import { useMerchandiser } from 'src/data/merchandiser'
import { trackEvent } from 'src/utils/analytics'
import { DocumentIcon, Flex, MenuItem, useToast } from 'src/components/designsystem'
import { downloadFile, FileType } from 'src/data'
import getClient from 'src/utils/clients/get-client'

export function PdfViewLink({
  slug,
  item,
  itemType,
}: Readonly<{
  slug: string
  item: Contract | Settlement
  itemType: 'contract' | 'settlement'
}>) {
  const { userId } = useMerchandiser()
  const href = makePdfLink({ slug, userId, item, itemType })
  const pdfTypeCapitalized = itemType.charAt(0).toUpperCase() + itemType.slice(1).toLowerCase()

  const isNativeApp = getClient().isNativeApp
  const toast = useToast()
  const { selectedUserId } = useMerchandiser()

  const { mutate, isPending } = useMutation({
    mutationFn: async () => {
      const response = await fetch(
        [window.location.origin, 'api', `${itemType}s`, 'pdf'].join('/'),
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            companySlug: slug,
            itemId: item.id,
            impersonateId: selectedUserId ?? null,
          }),
        }
      )

      if (!response.ok) {
        throw new Error('Unable to fetch PDF')
      }

      // Get file name from PDF response header -> Content-Disposition: attachment; filename="filename.pdf"
      const responseHeaderFilename = response.headers
        .get('content-disposition')
        ?.split('filename=')[1]
        ?.split('"')
        .join('')

      return downloadFile({
        apiResponse: response,
        fileType: FileType.PDF,
        fileNameOverride: responseHeaderFilename || null,
      })
    },
    onError: (error: any) => {
      toast({
        status: 'error',
        title: error?.message ?? 'Failed to export',
      })
    },
  })

  const linkProps = !isNativeApp
    ? {
        as: Link,
        href,
        prefetch: false,
        rel: 'noreferrer',
        target: '_blank',
      }
    : {}

  const onClick: MouseEventHandler<HTMLButtonElement> = useCallback(
    (e) => {
      trackEvent(pdfTypeCapitalized, 'View PDF')
      e.stopPropagation()

      if (isNativeApp) {
        mutate()
      }
    },
    [isNativeApp, mutate, pdfTypeCapitalized]
  )

  return (
    <MenuItem
      {...linkProps}
      aria-label="View PDF"
      icon={<DocumentIcon fontSize="2xl" />}
      onClick={onClick}
      isDisabled={isPending}
    >
      <Flex flexGrow={1} justifyContent="space-between">
        View PDF
      </Flex>
    </MenuItem>
  )
}

export function makePdfLink({
  slug,
  userId,
  item,
  itemType,
}: {
  slug: string
  userId?: string
  item: Contract | Settlement
  itemType: 'contract' | 'settlement'
}) {
  const base = userId ? `/${slug}/${userId}` : `/${slug}`
  return `${base}/${itemType}s/${item.id}/pdf`
}
